.ContentUsers .Modal-body {
  padding: 0px !important;
}
.ContentUsers .Modal-content {
  max-width: 700px;
  transform: translate(350px, -80px) !important;
}

.ContentUsers .ThirdRow select {
  height: 35px !important;
}
