.sidebar-filters .dropdown {
  width: 100%;
  background-color: transparent;
}

.sidebar-filters .dropdown-trigger {
  width: 100% !important;
  background-color: transparent !important;
  border: none !important;
}

.sidebar-filters .dropdown-trigger a {
  display: inherit !important;
}

.sidebar-filters .tag-item {
  width: 100% !important;
}

.sidebar-filters .dropdown-trigger {
  display: initial !important;
}

.sidebar-filters .dropdown input {
  background-color: transparent;
  width: 100% !important;
}

.sidebar-filters
  .react-dropdown-tree-select
  .dropdown
  .dropdown-trigger.arrow.top:after {
  content: '';
}

.sidebar-filters .dropdown-content {
  width: 100%;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: black;
  padding: 0 !important;
}

.sidebar-filters input[type='checkbox'] {
  clip: unset;
  clip-path: none;
  position: initial;
  width: 18px !important;
  height: 18px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #000;
  background-color: #fff;
  accent-color: #000;
}

.sidebar-filters .dropdown i {
  display: none !important;
}

.sidebar-filters .node.leaf {
  padding: 1px;
}
