.Modal-body {
  padding: 3rem 1rem 1rem 2rem !important;
}
.Modal-footer {
  padding: 0.5rem 2rem 2rem !important;
}

.Modal-footer .Button {
  height: 50px !important;
}

.Modal-footer .Button .label {
  font-size: 0.8rem !important;
}

.create-product-form #textinput,
input {
  font-size: 1rem !important;
}
