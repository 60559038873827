@tailwind base;
@tailwind components;

.icon-first {
  padding-left: 30px !important;
}

.icon-first:after {
  content: '';
  background: url('./assets/ic_arrow.png');
  position: absolute;
  background-repeat: no-repeat;
  left: 8px;
  height: 10px;
  width: 10px;
  opacity: 0.2;
  top: calc(40%);
}

.ag-row-focus.ag-row-selected {
  font-weight: bold;
  z-index: 5;
}

.ag-row-focus.ag-row-selected .icon-first:after {
  opacity: 1;
}

.icon-ellipsis {
  opacity: 0.5;
}

.ag-row-focus.ag-row-selected .icon-ellipsis {
  opacity: 1;
}

div.ag-theme-alpine div.ag-row {
  font-size: 12px;
}

.ag-header {
  z-index: 3;
}

.ag-header-row.ag-header-row-column {
  background-color: #2c2c2c;
  border-color: transparent;
  color: white;
  width: 100% !important;
}

.ag-side-button-label {
  font-weight: bold;
  color: #000;
}

.ag-side-button-button {
  flex-direction: row;
}

.ag-side-button.ag-selected .ag-side-button-button {
  border-left-color: #000 !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after,
.ag-theme-alpine .ag-checkbox-input-wrapper::after,
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::after {
  color: #2c2c2c;
  /* @apply tw-text-primary; */
  alpine-active-color: deeppink;
}

.ag-tool-panel-wrapper {
  background-color: #fafafa;
  z-index: 3;
}

.ag-react-container {
  height: 100%;
  width: 100%;
}

.ag-tool-panel-wrapper .ag-react-container {
  width: 100%;
}

.ag-tool-panel-wrapper .ag-react-container {
  width: 100%;
}

.ag-header-cell {
  font-size: 12px;
}

.ag-header-cell-text {
  font-size: 12px !important;
}

.ag-header-cell-label {
  color: #fff !important;
}

.ag-column-panel {
  z-index: 50;
}

.ag-row,
.ag-row.ag-row-odd {
  border: 0 !important;
}

.ag-row.ag-row-odd {
  background-color: #f7fbfc !important;
}

.ag-row-focus.ag-row-selected {
  background: rgba(59, 130, 246, 0.1) !important;
  /* background: #e8f4f5 !important; */
  opacity: 1;
}
.ag-row.ag-row-odd.ag-row-selected {
  background: rgba(59, 130, 246, 0.1) !important;
}
.ag-row.ag-row.ag-row-selected {
  background: rgba(59, 130, 246, 0.1) !important;
}

.ag-header-icon {
  color: white !important;
}

/* .ag-center-cols-container {
    width: 100% !important;
} */

.ag-status-bar {
  padding: 0 !important;
}

.ag-status-bar-center {
  width: 100%;
}

.ag-theme-alpine .ag-column-drop-horizontal {
  border: 0;
  background: #2c2c2c;
}

.ag-column-drop-list.ag-column-drop-horizontal-list {
  color: white;
}

.ag-header-row.ag-header-row-floating-filter {
  background: #f6f6f6;
}

.ag-column-drop-cell.ag-column-drop-horizontal-cell {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
}

.ag-icon.ag-icon-cancel {
  color: white;
}

.ag-status-bar-center {
  width: 100%;
}

.ag-theme-alpine .ag-column-drop-horizontal {
  border: 0;
  background: #2c2c2c;
}

.ag-unselectable.ag-column-drop.ag-column-drop-horizontal {
  color: white;
}

/*  */

.ag-header-row.ag-header-row-floating-filter {
  background: #f6f6f6;
}

.ag-column-drop-list.ag-column-drop-horizontal-list,
.ag-column-drop-empty-message.ag-column-drop-horizontal-empty-message {
  color: white;
}

.ag-theme-alpine .ag-icon-tree-closed:hover {
  color: transparent;
}

.ag-icon:hover {
  color: transparent !important;
}

.ag-cell-wrapper {
  align-items: center !important;
}

.ag-row-loading {
  opacity: 0;
}

.ag-row-loading:first-child {
  opacity: 1;
}

.ag-pinned-right-header,
.ag-cell-first-right-pinned,
.ag-right-aligned-cell {
  border: none !important;
}

.ag-theme-alpine .ag-ltr .ag-pinned-right-header .ag-header-row::after {
  right: inherit;
}
